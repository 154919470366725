/* ImagePopup.css */
.image-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .popup-content {
    max-width: 90%;
    max-height: 90%;
  }
  
  .popup-content img {
    width: 450px;
    object-fit: contain;
  }
  
  /* Optional styles to handle overflow for small screens */
  @media (max-width: 650px) {
    .popup-content img {
        width: 350px;
    }
  }
  